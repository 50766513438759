import React, { useState } from "react";
import "./MainSlidersStyles.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./AllSwiperSlidesStyle.css";
import { Keyboard, Pagination, Navigation } from "swiper";
import { TfiClose } from "react-icons/tfi";

import LivingDining0 from "..//assets/LivingDining/LivingDining0.jpg";
import LivingDining1 from "..//assets/LivingDining/LivingDining1.jpg";
import LivingDining2 from "..//assets/LivingDining/LivingDining2.jpg";
import LivingDining3 from "..//assets/LivingDining/LivingDining3.jpg";
import LivingDining4 from "..//assets/LivingDining/LivingDining4.jpg";
import LivingDining9 from "..//assets/LivingDining/LivingDining9.jpg";
import LivingDining6 from "..//assets/LivingDining/LivingDining6.jpg";
import LivingDining7 from "..//assets/LivingDining/LivingDining7.jpg";
import LivingDining8 from "..//assets/LivingDining/LivingDining8.jpg";
import LivingDining10 from "..//assets/LivingDining/LivingDining10.jpg";
import LivingDining11 from "..//assets/LivingDining/LivingDining11.jpg";
import LivingDining12 from "..//assets/LivingDining/LivingDining12.jpg";
import LivingDining13 from "..//assets/LivingDining/LivingDining13.jpg";

function SlideLivingDining() {
  const [isSlideOpen, setIsSlideOpen] = useState(false);

  const openSlide = () => {
    setIsSlideOpen(true);
  };
  const closeSlide = () => {
    setIsSlideOpen(false);
  };
  return (
    <>
      {/* front foto */}
      <div
        className="front-img "
        data-aos="fade-up"
        data-aos-delay="300"
        style={{
          backgroundImage: `url(${LivingDining3})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom",
        }}
        onClick={openSlide}
      >
        <div className="absolute inset-0 opacity-50 hover:opacity-0 gap-5 transition duration-300 bg-black flex flex-col justify-center items-center p-7 ">
          <h1 className="text-white font-normal text-center  sm:text-xl md:text-xl lg:text-xl xl:text-2xl 2xl:text-3xl  ">
            Living, Dining-Kitchen Area Design
          </h1>
        </div>
      </div>

      {/* front foto */}

      {/* //Slider */}
      {isSlideOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black/30 z-50 flex justify-center items-center"
          data-aos="zoom-in"
          data-aos-delay="300"
        >
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            keyboard={{
              enabled: true,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Keyboard, Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img src={LivingDining0} alt="Dining-Kitchen0" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={LivingDining3} alt="Dining-Kitchen3" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={LivingDining2} alt="Dining-Kitchen2" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={LivingDining1} alt="Dining-Kitchen1" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={LivingDining4} alt="Dining-Kitchen4" />
            </SwiperSlide>

            <SwiperSlide>
              <img src={LivingDining6} alt="Dining-Kitchen6" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={LivingDining7} alt="Dining-Kitchen7" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={LivingDining8} alt="Dining-Kitchen8" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={LivingDining9} alt="Dining-Kitchen9" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={LivingDining10} alt="Dining-Kitchen10" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={LivingDining11} alt="Dining-Kitchen11" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={LivingDining12} alt="Dining-Kitchen12" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={LivingDining13} alt="Dining-Kitchen13" />
            </SwiperSlide>
          </Swiper>

          <button
            className="absolute top-0 right-0 m-2 p-2    "
            onClick={closeSlide}
          >
            <TfiClose className="text-xl text-white" />
          </button>
        </div>
      )}
      {/* //Slider */}
    </>
  );
}

export default SlideLivingDining;
