import React from "react";
import "./MainSlidersStyles.css";
import SlideAccentLiving from "./SlideAccentLiving";
import SlideDarkShades from "./SlideDarkShades";
import SlideHomeOffice from "./SlideHomeOffice";
import SlideLivingDining from "./SlideLivingDining";
import SlideMasterBedroom from "./SlideMasterBedroom";
import SlideDarkModern from "./SlideDarkModern";

function MainSliders() {
  return (
    <>
      <div className="main-slider">
        <SlideDarkModern />
        <SlideAccentLiving />
        <SlideDarkShades />
        <SlideHomeOffice />
        <SlideLivingDining />
        <SlideMasterBedroom />
      </div>
    </>
  );
}

export default MainSliders;
