import React, { useState } from "react";
import "./MainSlidersStyles.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./AllSwiperSlidesStyle.css";
import { Keyboard, Pagination, Navigation } from "swiper";
import { TfiClose } from "react-icons/tfi";

import MasterBedroom0 from "..//assets/MasterBedroom/MasterBedroom0.jpg";
import MasterBedroom1 from "..//assets/MasterBedroom/MasterBedroom1.jpg";
import MasterBedroom2 from "..//assets/MasterBedroom/MasterBedroom2.jpg";
import MasterBedroom3 from "..//assets/MasterBedroom/MasterBedroom3.jpg";
import MasterBedroom4 from "..//assets/MasterBedroom/MasterBedroom4.jpg";
import MasterBedroom5 from "..//assets/MasterBedroom/MasterBedroom5.jpg";
import MasterBedroom9 from "..//assets/MasterBedroom/MasterBedroom9.jpg";
import MasterBedroom6 from "..//assets/MasterBedroom/MasterBedroom6.jpg";
import MasterBedroom7 from "..//assets/MasterBedroom/MasterBedroom7.jpg";
import MasterBedroom8 from "..//assets/MasterBedroom/MasterBedroom8.jpg";
import MasterBedroom10 from "..//assets/MasterBedroom/MasterBedroom10.jpg";
import MasterBedroom11 from "..//assets/MasterBedroom/MasterBedroom11.jpg";
import MasterBedroom12 from "..//assets/MasterBedroom/MasterBedroom12.jpg";
import MasterBedroom13 from "..//assets/MasterBedroom/MasterBedroom13.jpg";
import MasterBedroom14 from "..//assets/MasterBedroom/MasterBedroom14.jpg";

function SlideMasterBedroom() {
  const [isSlideOpen, setIsSlideOpen] = useState(false);

  const openSlide = () => {
    setIsSlideOpen(true);
  };
  const closeSlide = () => {
    setIsSlideOpen(false);
  };
  return (
    <>
      {/* front foto */}
      <div
        className="front-img "
        data-aos="fade-up"
        data-aos-delay="300"
        style={{
          backgroundImage: `url(${MasterBedroom5})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom",
        }}
        onClick={openSlide}
      >
        <div className="absolute inset-0 opacity-50 hover:opacity-0 gap-5 transition duration-300 bg-black flex flex-col justify-center items-center p-7 ">
          <h1 className="text-white font-normal text-center sm:text-xl md:text-xl lg:text-xl xl:text-2xl 2xl:text-3xl  ">
            Master Bedroom With Dressing Room
          </h1>
        </div>
      </div>

      {/* front foto */}

      {/* //Slider */}
      {isSlideOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black/30 z-50 flex justify-center items-center"
          data-aos="zoom-in"
          data-aos-delay="300"
        >
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            keyboard={{
              enabled: true,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Keyboard, Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img src={MasterBedroom0} alt="Master Bedroom0" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={MasterBedroom5} alt="Master Bedroom 5" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={MasterBedroom1} alt="Master Bedroom 1" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={MasterBedroom2} alt="Master Bedroom 2" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={MasterBedroom3} alt="Master Bedroom3" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={MasterBedroom4} alt="Master Bedroom4" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={MasterBedroom6} alt="Master Bedroom6" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={MasterBedroom7} alt="Master Bedroom7" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={MasterBedroom8} alt="Master Bedroom8" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={MasterBedroom9} alt="Master Bedroom9" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={MasterBedroom10} alt="Master Bedroom10 " />
            </SwiperSlide>
            <SwiperSlide>
              <img src={MasterBedroom11} alt="Master Bedroom11" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={MasterBedroom12} alt="Master Bedroom12" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={MasterBedroom13} alt="Master Bedroom13 " />
            </SwiperSlide>
            <SwiperSlide>
              <img src={MasterBedroom14} alt="Master Bedroom14" />
            </SwiperSlide>
          </Swiper>

          <button
            className="absolute top-0 right-0 m-2 p-2    "
            onClick={closeSlide}
          >
            <TfiClose className="text-xl text-white" />
          </button>
        </div>
      )}
      {/* //Slider */}
    </>
  );
}

export default SlideMasterBedroom;
