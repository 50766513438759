import { Link } from "react-router-dom";
import "./NavbarStyles.css";
import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import LogoImage from "..//assets/side.png";

function Navbar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  return (
    <nav className="header" data-aos="fade-down" data-aos-delay="500">
      <Link to="/">
        <img className="logo" src={LogoImage} alt="LogoImage" />
      </Link>
      <ul className={click ? "nav-menu active" : "nav-menu"}>
        <li>
          <Link to="/">HOME</Link>
        </li>
        <li>
          <Link to="/project">PROJECTS</Link>
        </li>
        <li>
          <Link to="/about">ABOUT</Link>
        </li>
        <li>
          <Link to="/contact">CONTACT</Link>
        </li>
      </ul>
      <div className="hamburger" onClick={handleClick}>
        {click ? (
          <FaTimes size={20} style={{ color: "#fefefe" }} />
        ) : (
          <FaBars size={20} style={{ color: "#fefefe" }} />
        )}
      </div>
    </nav>
  );
}

export default Navbar;
