import React, { useState } from "react";
import "./MainSlidersStyles.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./AllSwiperSlidesStyle.css";
import { Keyboard, Pagination, Navigation } from "swiper";
import { TfiClose } from "react-icons/tfi";

import DarkShades0 from "..//assets/DarkShades/DarkShades0.jpg";
import DarkShades1 from "..//assets/DarkShades/DarkShades1.jpg";
import DarkShades2 from "..//assets/DarkShades/DarkShades2.jpg";
import DarkShades3 from "..//assets/DarkShades/DarkShades3.jpg";
import DarkShades4 from "..//assets/DarkShades/DarkShades4.jpg";
import DarkShades9 from "..//assets/DarkShades/DarkShades9.jpg";
import DarkShades6 from "..//assets/DarkShades/DarkShades6.jpg";
import DarkShades7 from "..//assets/DarkShades/DarkShades7.jpg";
import DarkShades8 from "..//assets/DarkShades/DarkShades8.jpg";
import DarkShades10 from "..//assets/DarkShades/DarkShades10.jpg";
import DarkShades11 from "..//assets/DarkShades/DarkShades11.jpg";
import DarkShades12 from "..//assets/DarkShades/DarkShades12.jpg";
import DarkShades13 from "..//assets/DarkShades/DarkShades13.jpg";

function SlideDarkShades() {
  const [isSlideOpen, setIsSlideOpen] = useState(false);

  const openSlide = () => {
    setIsSlideOpen(true);
  };
  const closeSlide = () => {
    setIsSlideOpen(false);
  };
  return (
    <>
      {/* front foto */}
      <div
        className="front-img "
        data-aos="fade-up"
        data-aos-delay="300"
        style={{
          backgroundImage: `url(${DarkShades7})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom",
        }}
        onClick={openSlide}
      >
        <div className="absolute inset-0 opacity-50 hover:opacity-0 gap-5 transition duration-300 bg-black flex flex-col justify-center items-center p-7 ">
          <h1 className="text-white font-normal text-center sm:text-xl md:text-xl lg:text-xl xl:text-2xl 2xl:text-3xl  ">
            Dark Shades Living Bathroom
          </h1>
        </div>
      </div>

      {/* front foto */}

      {/* //Slider */}
      {isSlideOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black/30 z-50 flex justify-center items-center"
          data-aos="zoom-in"
          data-aos-delay="300"
        >
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            keyboard={{
              enabled: true,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Keyboard, Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img src={DarkShades0} alt=" Dark Shades Living Bathroom0" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={DarkShades7} alt="Dark Shades Living Bathroom7" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={DarkShades2} alt="Dark Shades Living Bathroom2" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={DarkShades3} alt="Dark Shades Living Bathroom3" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={DarkShades4} alt="Dark Shades Living Bathroom4" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={DarkShades6} alt="Dark Shades Living Bathroom6" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={DarkShades1} alt="Dark Shades Living Bathroom1" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={DarkShades8} alt="Dark Shades Living Bathroom8" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={DarkShades9} alt="Dark Shades Living Bathroom9" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={DarkShades10} alt="Dark Shades Living Bathroom10" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={DarkShades11} alt="Dark Shades Living Bathroom11" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={DarkShades12} alt="Dark Shades Living Bathroom12" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={DarkShades13} alt=" Dark Shades Living Bathroom13" />
            </SwiperSlide>
          </Swiper>
          <button
            className="absolute top-0 right-0 m-2 p-2    "
            onClick={closeSlide}
          >
            <TfiClose className="text-xl text-white" />
          </button>
        </div>
      )}
      {/* //Slider */}
    </>
  );
}

export default SlideDarkShades;
