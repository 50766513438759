import React from "react";
import Navbar from "../components/Navbar";
import Footer2 from "../components/Footer2";
import HeroImg2 from "../components/HeroImg2";
import MainSliders from "../components/MainSliders";
const Project = () => {
  return (
    <div>
      <Navbar />
      <HeroImg2
        heading="DESIGN PROJECTS WE LOVE"
        text="Some of our recent projects"
      />
      <MainSliders />
      <Footer2 />
    </div>
  );
};

export default Project;
