import React from "react";
import "./HeroImgStyles.css";

import MasterBedroom6 from "..//assets/MasterBedroom/MasterBedroom6.jpg";
import AccentLiving5 from "..//assets/AccentLiving/AccentLiving5.jpg";
import DarkModern6 from "..//assets/DarkModern/DarkModern6.jpg";

import { Link } from "react-router-dom";
import Footer from "../components/Footer";

function HeroImg() {
  return (
    <div className="snap-y snap-mandatory overflow-auto  h-screen w-screen overflow-x-hidden">
      <div
        className="snap-start  h-screen w-screen"
        style={{
          backgroundImage: `url(${AccentLiving5})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom",
        }}
      >
        <div className="content">
          <h1 data-aos="fade-down" data-aos-delay="400">
            CREATE A HOME YOU LOVE
          </h1>
          <p>Interior Design Solutions for Every Room</p>
          <div>
            <Link
              to="/project"
              className="btn "
              data-aos="fade-right"
              data-aos-delay="600"
            >
              Projects
            </Link>
            <Link
              to="/contact"
              className="btn "
              data-aos="fade-left"
              data-aos-delay="600"
            >
              Contact
            </Link>
          </div>
        </div>
      </div>
      <div
        className="snap-start  h-screen w-screen  "
        style={{
          backgroundImage: `url(${MasterBedroom6})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom",
        }}
      >
        <div className="content">
          <h1>SEE OUR WORK IN ACTION</h1>
          <div>
            <Link to="/project" className="btn ">
              Projects
            </Link>
          </div>
        </div>
      </div>
      <div
        className="snap-start  h-screen w-screen  "
        style={{
          backgroundImage: `url(${DarkModern6})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom",
        }}
      >
        <div className="content">
          <h1>CONTACT US TODAY TO SCHEDULE A CONSULTATION!</h1>
          <div>
            <Link to="/contact" className="btn ">
              Contact
            </Link>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default HeroImg;
