import React from "react";
import Navbar from "../components/Navbar";
import Footer2 from "../components/Footer2";
import HeroImg2 from "../components/HeroImg2";
import AboutContent from "../components/AboutContent";

const About = () => {
  return (
    <div>
      <Navbar />
      <HeroImg2
        heading="WORK WITH US"
        text="We are an interior design company from Chișinau."
      />
      <AboutContent />
      <Footer2 />
    </div>
  );
};

export default About;
