import { useRef } from "react";
import "./HeroContactStyles.css";
import emailjs from "@emailjs/browser";
import { FaPhone, FaHome, FaMailBulk } from "react-icons/fa";

function HeroContact() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_12x98xu",
        "template_lrmkihc",
        form.current,
        "p3dT9-q2LKt-tC_rz"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <>
      <div className="hero-img-contact ">
        <h1 className=" text-4xl text-center p-4 my-3 max-[600px]:text-lg max-[600px]:p-2 my-1  sm:text-lg sm:p-2 my-1 md:text-xl md:p-2 my-1 lg:text-2xl xl:text-3xl 2xl:text-4xl   ">
          Contact Us: We're Here to Help
        </h1>
      </div>
      <div className="w-full h-full my-4 ">
        <div
          id="contact"
          className=" relative max-w-[1000px] h-auto rounded  flex flex-row items-center justify-center gap-20 p-8 m-auto   max-[1080px]:flex-col "
        >
          <form
            ref={form}
            onSubmit={sendEmail}
            id="form"
            className="w-full max-w-sm  "
            data-aos="fade-down"
            data-aos-delay="500"
          >
            <h1 className=" text-2xl text-center p-4 my-3 max-[600px]:text-lg max-[600px]:p-2 my-1  sm:text-lg sm:p-2 my-1 md:text-xl md:p-2 my-1 lg:text-xl xl:text-xl 2xl:text-2xl   ">
              Contact Us
            </h1>
            <div className="md:flex md:items-center mb-6">
              <div className="md:w-1/3 xl:w-1/6">
                <label
                  className="text-xl mb-1 block text-white  max-[600px]:text-base sm:text-lg md:text-xl md:mb-0 pr-4  md:text-right   "
                  htmlFor="user_name"
                >
                  Name
                </label>
              </div>
              <div className=" md:w-2/3 xl:w-5/6">
                <input
                  className="bg-transparent appearance-none border-b  border-white  w-full py-2 px-4 text-white  focus:outline-none  focus:border-white max-[600px]:py-1 px-2 md:py-1 px-2 lg:py-1 px-2 "
                  id="user_name"
                  name="user_name"
                  type="text"
                  autoComplete="on"
                  required
                />
              </div>
            </div>
            <div className="md:flex md:items-center mb-6">
              <div className="md:w-1/3 xl:w-1/6">
                <label
                  className="text-xl mb-1 block text-white  max-[600px]:text-base sm:text-lg md:text-xl md:mb-0 pr-4  md:text-right"
                  htmlFor="user_email"
                >
                  Email
                </label>
              </div>
              <div className="md:w-2/3 xl:w-5/6">
                <input
                  className="bg-transparent appearance-none border-b border-white  w-full py-2 px-4 text-white  focus:outline-none  focus:border-white max-[600px]:py-1 px-2 md:py-1 px-2 lg:py-1 px-2"
                  id="user_email"
                  name="user_email"
                  type="email"
                  autoComplete="on"
                  required
                />
              </div>
            </div>
            <div className="md:flex md:items-center mb-6">
              <div className="md:w-1/3 xl:w-1/6">
                <label
                  className="text-xl mb-1 block text-white  max-[600px]:text-base sm:text-lg md:text-xl md:mb-0 pr-4  md:text-right"
                  htmlFor="user_phone"
                >
                  Phone
                </label>
              </div>
              <div className="md:w-2/3 xl:w-5/6">
                <input
                  className="bg-transparent appearance-none border-b border-white  w-full py-2 px-4 text-white  focus:outline-none  focus:border-white max-[600px]:py-1 px-2 md:py-1 px-2 lg:py-1 px-2"
                  id="user_phone"
                  name="user_phone"
                  type="text"
                  autoComplete="on"
                  required
                />
              </div>
            </div>
            <div className="md:flex md:items-center mb-6 ">
              <div className="md:w-1/3 xl:w-1/6"></div>
              <div className="md:w-2/3 xl:w-5/6">
                <textarea
                  className="bg-transparent  border-white  w-full py-2 px-4 text-white  border focus:outline-none  focus:border-white max-[600px]:py-1 px-2 md:py-1 px-2 lg:py-1 px-2"
                  name="message"
                  rows="5"
                  cols="33"
                  placeholder="Write your questions!"
                  autoComplete="on"
                ></textarea>
              </div>
            </div>
            <div className="md:flex md:items-center ">
              <div className=" md:w-1/3 xl:w-1/6"></div>
              <div className="md:w-2/3 xl:w-5/6 ">
                <button
                  className=" border border-white bg-transparent w-full hover:bg-white  hover:text-black focus:shadow-outline focus:outline-none text-white  py-2 px-4 rounded max-[600px]:py-1 px-2 "
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
          <div id="contact-social" className="w-full max-w-sm    ">
            <div className="max-w-sm  flex flex-row items-center   ">
              <FaPhone
                className="text-white  max-[600px]:text-lg sm:text-lg lg:text-xl md:text-2xl xl:text-3xl 2xl:text-3xl"
                style={{ color: "#fff" }}
                data-aos="fade-down"
                data-aos-delay="500"
              />
              <div className=" py-4 px-6  max-[600px]:py-0 max-[600px]:px-2 sm:py-0 md:py-0 lg:py-0 xl:py-4 2xl:py-4 ">
                <div
                  className=" text-base mb-2 text-white max-[600px]:text-xs  sm:text-lg md:text-lg lg:text-xl xl:text-xl 2xl:text-xl "
                  data-aos="fade-down"
                  data-aos-delay="500"
                >
                  (+373)69074794
                </div>
              </div>
            </div>
            <div className="max-w-sm  flex flex-row items-center ">
              <FaHome
                className="text-white max-[600px]:text-lg sm:text-lg lg:text-xl md:text-2xl xl:text-3xl 2xl:text-3xl"
                style={{ color: "#fff" }}
                data-aos="fade-down"
                data-aos-delay="500"
              />
              <div className=" py-4 px-6   max-[600px]:py-0 max-[600px]:px-2 sm:py-0 md:py-0 lg:py-0 xl:py-4 2xl:py-4  ">
                <div
                  className=" text-base mb-2 text-white max-[600px]:text-xs  sm:text-lg md:text-lg lg:text-xl xl:text-xl 2xl:text-xl"
                  data-aos="fade-down"
                  data-aos-delay="500"
                >
                  Chișinău, Moldova
                </div>
              </div>
            </div>
            <div className="max-w-sm  flex flex-row items-center ">
              <FaMailBulk
                className="text-white max-[600px]:text-lg sm:text-lg lg:text-xl md:text-2xl xl:text-3xl 2xl:text-3xl"
                style={{ color: "#fff" }}
              />
              <div className=" py-4 px-6  max-[600px]:py-0 max-[600px]:px-2 sm:py-0 md:py-0 lg:py-0 xl:py-4 2xl:py-4">
                <div className=" text-base mb-2 text-white max-[600px]:text-xs  sm:text-lg md:text-lg lg:text-xl xl:text-xl 2xl:text-xl">
                  sideinteriors.md@gmail.com
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroContact;
