import React from "react";
import "./AboutContentStyles.css";
import { MdProductionQuantityLimits } from "react-icons/md";
import { SiLevelsdotfyi, SiDatadotai } from "react-icons/si";
import { AiOutlineContainer } from "react-icons/ai";
import About from "..//assets/about.jpeg";

const links = [
  { name: "Home", href: "/" },
  { name: "Projects", href: "/project" },
  { name: "About", href: "about" },
  { name: "Contact", href: "contact" },
];
const stats = [
  { name: "Projects completed", value: "7" },
  { name: "Team members", value: "3+" },
  { name: "Design awards", value: "2+" },
  { name: "Happy clients", value: "Unlimited" },
];

function AboutContent() {
  return (
    <div className="about">
      <div
        id="service"
        className=" relative flex-wrap top-[-90px] object-cover w-[1000px]  max-w-full h-auto flex  items-center justify-between  px-8 py-8 max-[600px]:flex-col max-[600px]:items-start  md:flex-row  "
      >
        <div className="flex flex-col cursor-pointer scale-90 hover:scale-100  ease-in duration-500 items-center max-[600px]:flex-row items-start justify-center gap-4">
          <AiOutlineContainer className="text-white duration-500 text-7xl max-[1080px]:text-4xl" />
          <h3 className="text-white text-center text-xl py-4 max-[1080px]:text-lg ">
            Planning
          </h3>
        </div>
        <div className="flex flex-col cursor-pointer  scale-90 hover:scale-100  ease-in duration-500 items-center max-[600px]:flex-row items-start justify-center gap-4">
          <SiLevelsdotfyi className="text-white text-7xl max-[1080px]:text-4xl " />
          <h3 className="text-white text-center text-xl py-4 max-[1080px]:text-lg ">
            Design
          </h3>
        </div>
        <div className="flex flex-col cursor-pointer  scale-90 hover:scale-100  ease-in duration-500 items-center max-[600px]:flex-row items-start justify-center gap-4">
          <SiDatadotai className="text-white  text-7xl max-[1080px]:text-4xl" />
          <h3 className="text-white text-center text-xl py-4 max-[1080px]:text-lg ">
            3D Visualisation
          </h3>
        </div>
        <div className="flex flex-col cursor-pointer  scale-90 hover:scale-100  ease-in duration-500 items-center max-[600px]:flex-row items-start justify-center gap-4 ">
          <MdProductionQuantityLimits className="text-white   text-7xl max-[1080px]:text-4xl " />

          <h3 className="text-white text-center text-xl py-4 max-[1080px]:text-lg  ">
            Material purchasing
          </h3>
        </div>
      </div>
      <div className="flex flex-row max-[1080px]:flex-col">
        <div
          className="relative isolation overflow-hidden  bg-[#262626] rounded    py-10 sm:py-24 "
          data-aos="flip-left"
          data-aos-delay="100"
        >
          <div
            className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
            aria-hidden="true"
          >
            <div className="aspect-[1097/845] w-[68.5625rem]  opacity-20" />
          </div>
          <div
            className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
            aria-hidden="true"
          >
            <div className="aspect-[1097/845] w-[68.5625rem]  opacity-20" />
          </div>
          <div className="mx-auto  max-w-7xl  px-6 lg:px-8">
            <div className="mx-auto max-w-xl lg:mx-0">
              <h2 className="text-4xl font-bold  tracking-tight text-white sm:text-6xl">
                Welcome to SIDE INTERIORS!
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-300 text-justify">
                We are an interior design company based in Chisinau. We are
                dedicated to creating personalized and functional interiors that
                reflect the style and personality of each client. We believe
                that interior design should be a pleasant and inspiring
                experience, and that each project should be unique in its own
                way. We are passionate about what we do and we love to see our
                ideas come to life in every project we manage. From the initial
                concept to the final implementation, we are with our clients to
                ensure that their project is managed professionally and
                efficiently. We invite you to explore our portfolio and to get
                in touch with us to discuss how we can transform the interior of
                your home into a unique and personalized space.
              </p>
            </div>
            <div className="mx-auto mt-10 max-w-xl lg:mx-0 lg:max-w-none">
              <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-white sm:grid-cols-2 md:flex lg:gap-x-10">
                {links.map((link) => (
                  <a key={link.name} href={link.href}>
                    {link.name} <span aria-hidden="true">&rarr;</span>
                  </a>
                ))}
              </div>
              <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
                {stats.map((stat) => (
                  <div key={stat.name} className="flex flex-col-reverse">
                    <dt className="text-base leading-7 text-gray-300">
                      {stat.name}
                    </dt>
                    <dd className="text-xl font-bold leading-9 tracking-tight text-white">
                      {stat.value}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
        <div className="right" data-aos="flip-right" data-aos-delay="100">
          <img src={About} className="img" alt="Side card" />
          <div className="right-info">
            <h1>Silvia Delepeev</h1>
            <p>
              Hello everyone! I'm an interior designer and the founder of SIDE
              INTERIORS. As an interior designer, I like to explore and
              experiment with different design styles to create spaces that are
              in line with the preferences and tastes of each client. I enjoy
              staying up-to-date with the trends and news in the world of
              interior design so that I can always provide the best service to
              my clients.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutContent;
