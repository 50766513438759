import React from "react";
import Navbar from "../components/Navbar";
import HeroContact from "../components/HeroContact";
import Footer2 from "../components/Footer2";

const Contact = () => {
  return (
    <div>
      <Navbar />
      <HeroContact />
      <Footer2 />
    </div>
  );
};

export default Contact;
