import "./FooterStyles.css";
import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-container">
        <Link to="/about">
          <p>SIDE INTERIORS &copy; 2023</p>
        </Link>
        <Link to="/contact">
          <p>Contact</p>
        </Link>
        <div className="social">
          <Link
            to="https://www.facebook.com/profile.php?id=100091440079663"
            aria-label="Go to Facebook search and side interiors"
          >
            <FaFacebook className="text-white max-[600px]:text-xl sm:text-xl lg:text-xl md:text-2xl xl:text-3xl 2xl:text-3xl" />
          </Link>
          <Link
            to="https://www.instagram.com/side_interiors/"
            aria-label="Go to Instagram and search side interiors"
          >
            <FaInstagram className="text-white max-[600px]:text-xl sm:text-xl lg:text-xl md:text-2xl xl:text-3xl 2xl:text-3xl" />
          </Link>
          <Link
            to="https://www.linkedin.com/in/silvia-delepeev-68934425b/"
            aria-label="Go to Linkedin and search side interiors"
          >
            <FaLinkedin className="text-white max-[600px]:text-xl sm:text-xl lg:text-xl md:text-2xl xl:text-3xl 2xl:text-3xl" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
