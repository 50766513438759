import React, { useState } from "react";
import "./MainSlidersStyles.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./AllSwiperSlidesStyle.css";
import { Keyboard, Pagination, Navigation } from "swiper";
import { TfiClose } from "react-icons/tfi";

import HomeOffice0 from "..//assets/HomeOffice/HomeOffice0.jpg";
import HomeOffice1 from "..//assets/HomeOffice/HomeOffice1.jpg";
import HomeOffice2 from "..//assets/HomeOffice/HomeOffice2.jpg";
import HomeOffice3 from "..//assets/HomeOffice/HomeOffice3.jpg";
import HomeOffice4 from "..//assets/HomeOffice/HomeOffice4.jpg";

function SlideHomeOffice() {
  const [isSlideOpen, setIsSlideOpen] = useState(false);

  const openSlide = () => {
    setIsSlideOpen(true);
  };
  const closeSlide = () => {
    setIsSlideOpen(false);
  };
  return (
    <>
      {/* front foto */}
      <div
        className="front-img "
        data-aos="fade-up"
        data-aos-delay="300"
        style={{
          backgroundImage: `url(${HomeOffice1})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom",
        }}
        onClick={openSlide}
      >
        <div className="absolute inset-0 opacity-50 hover:opacity-0 gap-5 transition duration-300 bg-black flex flex-col justify-center items-center p-7 ">
          <h1 className="text-white font-normal text-center sm:text-xl md:text-xl lg:text-xl xl:text-2xl 2xl:text-3xl  ">
            Home Office
          </h1>
        </div>
      </div>

      {/* front foto */}

      {/* //Slider */}
      {isSlideOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black/30 z-50 flex justify-center items-center"
          data-aos="zoom-in"
          data-aos-delay="300"
        >
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            keyboard={{
              enabled: true,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Keyboard, Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img src={HomeOffice0} alt="Home Office0" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={HomeOffice4} alt="Home Office4" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={HomeOffice2} alt="Home Office2" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={HomeOffice3} alt="Home Office3" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={HomeOffice1} alt="Home Office1" />
            </SwiperSlide>
          </Swiper>
          <button
            className="absolute top-0 right-0 m-2 p-2    "
            onClick={closeSlide}
          >
            <TfiClose className="text-xl text-white" />
          </button>
        </div>
      )}
      {/* //Slider */}
    </>
  );
}

export default SlideHomeOffice;
